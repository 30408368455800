import Velocity from 'velocity-animate';
import 'velocity-animate/velocity.ui.min.js';

let list = [];
let len = list.length;
let wrapper;
let active;


function open_user_info(item) {
    let mount = undefined;
    let position = 'beforebegin';
    for(let i = parseInt(item.dataset.index, 10) + 1; i < len; i += 1) {
        if (list[i].offsetLeft <= list[i].offsetWidth / 2) { // FCKNG magic
            mount = list[i];
            break;
        }
    }

    if (!mount) {
        mount = list[len - 1];
        position = 'afterend';
    }

    let data = `<div class="people-more-block"><div class="people-more-container">${item.querySelector('.people-data').outerHTML}</div></div>`;

    item.classList.add('people-card_active');
    mount.insertAdjacentHTML(position, data);

    wrapper = document.querySelector('.people-more-block');
    let content = wrapper.querySelector('.people-more-container');

    wrapper.velocity({height: `${content.offsetHeight}px`}, {
        duration: 250,
        complete: () => {
            wrapper.style.height = 'auto';
        }
    });
    active = item;
}


function hide_user_info(item) {
    active.classList.remove('people-card_active');
    wrapper.velocity({height: '0px'}, {
        duration: 250,
        complete: () => {
            wrapper.remove();
            wrapper = undefined;
            active = undefined;
            if (item) {
                open_user_info(item)
            }
        }
    });
}


function user_details() {
    let self = this;
    parent = self.closest('.people-card');
    wrapper = document.querySelector('.people-more-block');

    if (parent.classList.contains('people-card_active')) {
        hide_user_info();
    } else if (wrapper) {
        hide_user_info(parent);
    } else {
        open_user_info(parent);
    }
}


function user_show_more() {
    list = document.querySelectorAll('.people-card');
    len = list.length;

    document.querySelectorAll('.people-show-btn').forEach((item) => {
        item.addEventListener('click', user_details)
    });
}

export default user_show_more;
